
import React, { Suspense, lazy } from 'react';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Lazy load Index component to reduce initial bundle size
const Index = lazy(() => import('./pages/Index'));
const CDNStrategyControl = lazy(() => import('./components/admin/CDNStrategyControl'));

// Create a single instance of QueryClient
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minute
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

// Loading fallback component with minimal footprint
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="text-red-800 font-medium">Laden...</div>
  </div>
);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LoadingFallback />}>
        <Index />
      </Suspense>
      
      {/* CDN Strategy Control - nur im Dev-Modus oder mit Admin-Zugang */}
      {process.env.NODE_ENV === 'development' && (
        <Suspense fallback={null}>
          <CDNStrategyControl />
        </Suspense>
      )}
    </QueryClientProvider>
  );
}

export default App;
