
import * as React from 'react';

/**
 * Initializes React globally to avoid issues with modules that expect React in the global scope
 */

// Make React available globally
try {
  (window as any).React = React;
} catch (e) {
  console.warn("Could not set window.React:", e);
}

// Ensure createContext is available on potential aliases
try {
  if (typeof window !== 'undefined') {
    // Define a helper function to safely set aliases
    const safelySetAlias = (aliasName: string) => {
      try {
        // Only set if not already defined
        if (!(window as any)[aliasName]) {
          (window as any)[aliasName] = React;
        } else if (!(window as any)[aliasName].createContext) {
          // If the alias exists but doesn't have createContext, add it
          (window as any)[aliasName].createContext = React.createContext;
        }
      } catch (e) {
        console.warn(`Could not set ${aliasName}:`, e);
      }
    };

    // Set common React aliases
    safelySetAlias('h');
    safelySetAlias('preact');
  }
} catch (e) {
  console.warn("Error setting React aliases:", e);
}

// Add global En class if it doesn't exist
try {
  if (typeof window !== 'undefined' && !(window as any).En) {
    (window as any).En = class {};
  }
} catch (e) {
  console.warn("Could not set window.En:", e);
}
