
// Import the React initializer first
import './reactInitializer';

import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'

// Make React available globally for Lovable
try {
  window.React = React;
} catch (e) {
  // Silent error in production
}

// Ensure createContext is available on potential aliases with safer approach
const reactAliases = ['h', 'React', 'react'];
reactAliases.forEach(alias => {
  try {
    // Check if the alias exists and doesn't have createContext
    if (window[alias as keyof Window] && !(window[alias as keyof Window] as any).createContext) {
      try {
        // Try to define the property safely
        Object.defineProperty(window[alias as keyof Window] as object, 'createContext', {
          value: React.createContext,
          writable: true,
          configurable: true
        });
      } catch (e) {
        // Silent error in production
      }
    }
  } catch (e) {
    // Silent error in production
  }
});

const rootElement = document.getElementById('root');

if (rootElement) {
  try {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
    );
  } catch (error) {
    // Fallback for critical errors
    rootElement.innerHTML = `
      <div style="text-align: center; padding: 2rem;">
        <h1>Es ist ein Fehler aufgetreten</h1>
        <p>Bitte versuchen Sie die Seite neu zu laden.</p>
        <button onclick="window.location.reload()" style="padding: 0.5rem 1rem; background: #990000; color: white; border: none; border-radius: 4px; cursor: pointer; margin-top: 1rem;">
          Neu laden
        </button>
      </div>
    `;
  }
} else {
  // Silent error in production
}
